import React, { useEffect, useState } from "react";
import { buildClassName, getChildNavigationLinks, getHrefFromNavigationLink } from "../../utils";
import logoImg from "../../images/travelworld_logo.png";
import Icon from "../icon";
import FullscreenNavigation, { FullscreenNavigationProps } from "./fullscreen-navigation";
import Link from "../link";
import NavigationQSM from "./navigation-qsm";
import { graphql } from "gatsby";
import {
  TideItemForDestinations,
  TideItemForNavigationLink,
  TideItemForNavigationSection,
} from "../../types";
import HotelsMegamenu from "./hotels-megamenu";
import RoundtripsMegamenu from "./roundtrips-megamenu";
import LanguageSelector from "../language-selector";
import { useI18next } from "gatsby-plugin-react-i18next";
import { AlternateRoute } from "../../../.gatsby/gatsby-node";
import { useMemberStore } from "../../app/member-store/context";
import { logoutMember } from "../../tide-api";
import { setMember } from "../../app/member-store/reducer";

interface NavbarProps extends FullscreenNavigationProps {
  mainNavigationSection?: TideItemForNavigationSection;
  fullscreenActive?: boolean;
  phone: string;
  destinations?: TideItemForDestinations;
  showProductSearch?: boolean;
  alternateRoutes?: AlternateRoute[];
}

const Navbar: React.FC<NavbarProps> = (props) => {
  const [memberState, memberDispatch] = useMemberStore();
  const [showUserBar, setShowUserBar] = useState<boolean>(false);
  const [hasFullscreenNavigationActive, setFullscreenNavigationActive] = useState<boolean>(
    props.fullscreenActive ?? false
  );

  useEffect(() => {
    setShowUserBar(!!memberState?.member);
  }, [memberState]);

  const handleFullscreenNavigationToggle: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setFullscreenNavigationActive(!hasFullscreenNavigationActive);
  };

  const { t, navigate } = useI18next();

  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const response = await logoutMember();
    if (response) {
      navigate("/");
      if (typeof localStorage !== "undefined") {
        localStorage.removeItem("token");
      }
      if (memberDispatch) {
        memberDispatch(setMember(undefined));
      }
    }
  };

  return (
    <nav
      className={buildClassName([
        "navigation",
        hasFullscreenNavigationActive && "navigation--active",
      ])}
    >
      <div
        className={buildClassName(["user__container", !showUserBar && "user__container--hidden"])}
      >
        <div className="user__wrapper">
          <Icon name="user" />
          <div className="user">
            <span>{t("WELCOME")}:</span>
            <div className="user__name">
              <span>{memberState?.member?.name}</span>
              <Icon name="arrow" />
              <div className="user__dropdown">
                <Link href="/dossiers" className="user__dropdown--link">
                  {t("FETCH_DOSSIERS")}
                </Link>
                <Link href="/facturen" className="user__dropdown--link">
                  {t("FETCH_INVOICES")}
                </Link>
                <Link
                  title={t("LOGOUT")}
                  onClick={(event) => handleLogout(event)}
                  className="user__dropdown--link"
                >
                  {t("LOGOUT")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navigation__links">
        <div className="navigation__links-container">
          <Link internal href="/" title="Travelworld" className="logo">
            <img src={logoImg} alt="Travelworld" className="logo__media" />
          </Link>
          {props.mainNavigationSection ? (
            <div className="navigation__primary-links">
              <div className="link-items">
                {getChildNavigationLinks(props.mainNavigationSection).map((tideItem) => {
                  const navigationLink = tideItem as TideItemForNavigationLink;
                  const hoverable = navigationLink.content?.general?.path?.startsWith("#");
                  return hoverable ? (
                    <div className="link-item" key={navigationLink.id}>
                      <div className="dropdown dropdown--hoverable">
                        <div className="dropdown__trigger">
                          <a
                            title={navigationLink.content?.general?.title ?? undefined}
                            className="link-item"
                          >
                            {navigationLink.content?.general?.title}
                          </a>
                        </div>
                        {navigationLink.name === "Vliegvakanties" && props.destinations && (
                          <div className="dropdown__options">
                            <HotelsMegamenu destinations={props.destinations} />
                          </div>
                        )}
                        {navigationLink.name === "Rondreizen" && props.destinations && (
                          <div className="dropdown__options">
                            <RoundtripsMegamenu destinations={props.destinations} />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <Link
                      key={navigationLink.id}
                      internal
                      href={getHrefFromNavigationLink(navigationLink)}
                      title={navigationLink.content?.general?.title ?? undefined}
                      className="link-item"
                    >
                      {navigationLink.content?.general?.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="navigation__secondary-links">
            <div className="link-items">
              {(props.showProductSearch ?? true) && (
                <div className="link-item">
                  <NavigationQSM />
                </div>
              )}
              <a
                href={`tel:${props.phone}`}
                title={t("CALL_US", { phone: props.phone })}
                className="link-item"
              >
                <Icon name="social-phone" />
                {props.phone}
              </a>
              <div className="language-selector">
                <LanguageSelector alternateRoutes={props.alternateRoutes}></LanguageSelector>
              </div>
              <div className="link-item link-item--hamburger">
                <button
                  type="button"
                  className="button button--hamburger"
                  title="Menu"
                  onClick={handleFullscreenNavigationToggle}
                >
                  <span className="button__icon"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FullscreenNavigation
        fullscreenPrimaryNavigationSection={props.fullscreenPrimaryNavigationSection}
        fullscreenSecondaryNavigationSection={props.fullscreenSecondaryNavigationSection}
        legalNavigationSection={props.legalNavigationSection}
        socialNavigationSection={props.socialNavigationSection}
      />
    </nav>
  );
};

export default Navbar;

export const navigationFragment = graphql`
  fragment navigationDestinationsFragment on Query {
    navigationDestinations: tideItemForDestinations(language: { eq: $language }) {
      name
      content {
        general {
          title
          slug
        }
      }
      childItems {
        ... on TideItemForGeographicalRegion {
          id
          name
          templateName
          content {
            general {
              title
            }
          }
          childItems {
            ... on TideItemForCountry {
              id
              content {
                general {
                  title
                  slug
                  thumbnailPicture {
                    url
                  }
                }
              }
              childItems {
                templateName
                ... on TideItemForRegion {
                  childItems {
                    templateName
                  }
                }
              }
            }
            ... on TideItemForRegion {
              id
              content {
                general {
                  title
                  slug
                  thumbnailPicture {
                    url
                  }
                }
              }
              childItems {
                templateName
              }
            }
          }
        }
      }
    }
  }
`;
