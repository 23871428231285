import React from "react";
import { useMemberStore } from "../../app/member-store/context";
import { setMember } from "../../app/member-store/reducer";
import { logoutMember } from "../../tide-api";
import { TideItemForNavigationSection } from "../../types";
import { getChildNavigationSections } from "../../utils";
import Icon from "../icon";
import Legal from "../legal";
import Link from "../link";
import Social from "../social";
import FullscreenNavigationSection from "./fullscreen-navigation-section";
import { useI18next } from "gatsby-plugin-react-i18next";

export interface FullscreenNavigationProps {
  fullscreenPrimaryNavigationSection: TideItemForNavigationSection;
  fullscreenSecondaryNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
}

const FullscreenNavigation: React.FC<FullscreenNavigationProps> = ({
  fullscreenPrimaryNavigationSection,
  fullscreenSecondaryNavigationSection,
  legalNavigationSection,
  socialNavigationSection,
}) => {
  const [memberState, memberDispatch] = useMemberStore();
  const { t } = useI18next();

  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const response = await logoutMember();
    if (response) {
      if (typeof localStorage !== "undefined") {
        localStorage.removeItem("token");
      }
      if (memberDispatch) {
        memberDispatch(setMember(undefined));
      }
    }
  };

  return (
    <div className="navigation__fullscreen">
      <div className="navigation__fullscreen-container">
        <div className="navigation__fullscreen-group navigation__fullscreen-group--primary">
          {getChildNavigationSections(fullscreenPrimaryNavigationSection).map(
            (navigationSection) => (
              <FullscreenNavigationSection
                key={navigationSection.id}
                navigationSection={navigationSection}
              />
            )
          )}
        </div>
        <div className="navigation__fullscreen-group navigation__fullscreen-group--secondary">
          {getChildNavigationSections(fullscreenSecondaryNavigationSection).map(
            (navigationSection) => (
              <FullscreenNavigationSection
                key={navigationSection.id}
                navigationSection={navigationSection}
              />
            )
          )}
        </div>
        <div className="navigation__fullscreen-group navigation__fullscreen-group--tertiary">
          <Social socialNavigationSection={socialNavigationSection} />
          <Legal legalNavigationSection={legalNavigationSection} />
          {memberState?.member ? (
            <Link title="Logout" className="cta" onClick={(event) => handleLogout(event)}>
              <Icon name="qsm-user" />
              {t("LOGOUT")}
            </Link>
          ) : (
            <Link href="/login" internal title="Agent login" className="cta">
              <Icon name="qsm-user" />
              {t("AGENT_LOGIN")}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default FullscreenNavigation;
